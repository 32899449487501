import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Scroll from "../utils/scroll";
import HeroMini from "../sections/pear-landing/Hero-Mini-AM";
import MainOptimizeAcquisition from "../sections/pear-landing/Optimize-Acquisition-Channels-Blog";
import FutureProofing from "../sections/pear-landing/Future-Proffing-Your-Ecommerce-Site-Blog";
import RetailNetowrkBlog from "../sections/pear-landing/Retail-Media-Network-Sales-Blog.js";
import StartCapturingSales from "../sections/pear-landing/Start-Capturing-Sales-Blog.js";
import SuppliersSendingToComp from "../sections/pear-landing/Suppliers-Sending-To-Compertition-Blog.js"

const WebApplication = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          button: "cta", // cta, account, null
          buttonText: "Get started"
        }}
        footerConfig={{
          theme: "light",
          // style: "style1", //style1, style2
          style: "style5", //style1, style
        }}
      >
        <HeroMini />
        <div className="section-title text-center mb-11 mb-lg-19">
          <h2 className="title gr-text-3 mb-7">Retail Resources</h2>

        </div>
        {/* <Blog /> */}
        <FutureProofing />
        <MainOptimizeAcquisition />
        <Scroll showBelow={250} />
        <RetailNetowrkBlog />
        <StartCapturingSales />
        <SuppliersSendingToComp />

      </PageWrapper>
    </>
  );
};
export default WebApplication;
