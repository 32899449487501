import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { backgroundColor, bottom, color, position, right, zIndex } from 'styled-system';
import { transitions } from 'polished';

const useStyle = makeStyles((theme) => ({
  toTop: {
    zIndex: 2,
    position: 'fixed',
    bottom: '2vh',
    backgroundColor: '#DCDCDC',
    color: 'black',
    "&:hover, &.Mui-focusVisible": {
      transition: '0.3s',
      color: '#397BA6',
      backgroundColor: '#DCDCDC'
    },
    right: '5%',
  }
}));

const Scroll = ({
  showBelow
}) => {

  const classes = useStyle();

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (true) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  })


  return (
    <div>
      {show &&
        <IconButton onClick={handleClick} className={classes.toTop}>
          <ExpandLessIcon />
        </IconButton>
      }
    </div>
  )
}

export default Scroll