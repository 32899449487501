import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";

import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
//import imgHero from "../../assets/image/pear-dashboard.png";
import ebookCover from "../../assets/image/Whitepaper-Future-Proofing-Your-Ecommerce-Site-1.png";
import { Select } from "../../components/Core";
import styled from "styled-components";
import section2Graphic from "../../assets/image/retail_media_network.png";


const GraphicImg = styled.img`
  margin-top: 45px;
  margin-left: 100px;
  border-radius: 15px;
  background: #3d9739;
  padding: 10px;
  height: auto; 
  width: auto; 
  max-width: 475px; 
  max-height: 325px;
`;


const Unit = () => {

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div id="MainOptimizeAcquisition" className="hero-area-03 bg-default pt-1 pb-30 position-relative">
        <Container className="bg-default">
          <Row className="px-xs-16">
            <Col className="ml-lg-n16 px-lg-8" lg="8">
              <div className="align-items-left">
                <h1 className="gr-text-5 text-dark-green mb-10">
                  Ecommerce Enhancements to Increase <br />
                  Retail Media Network Sales
                </h1>
                <div className="text-dark">
                  <p className="gr-text-7 text-dark">
                    Much like in-store shelf space, you have digital ad placement inventory that CPGs are eager to fill.
                  </p>
                  <p className="gr-text-7 text-dark">
                    Get your logo in front of high-intent, conversion-optimized shoppers and unlock...
                  </p>
                  <p className="gr-text-7 text-dark">
                  </p>
                </div>
              </div>
              <div className="hero-btns d-flex justify-content-center  mt-15">
                <a href="/whitepaper-retail-media-network-sales">
                  <button className="text-white with-icon gr-hover-y mb-8 mb-md-5 mr-md-35 btn-blue btn">
                    Full Article!
                  </button>
                </a>
              </div>
            </Col>
            <Col className="d-none d-lg-block" lg="4">
              <div
                className="hero-img position-relative z-index-1"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <GraphicImg className="w-200" src={section2Graphic} alt="" />
                <div
                  className="pattern-sub-img gr-abs-tr-custom-2"
                  data-aos="fade-up-right"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
