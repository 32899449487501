import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
//import imgHero from "../../assets/image/pear-dashboard.png";
import imgHero from "../../assets/image/pear-section-1-hero.png";

import { Select } from "../../components/Core";

import imgHB from "../../assets/image/job-site/png/hero-img-big.png";
import imgDot from "../../assets/image/job-site/png/hero-dot-pattern.png";
import imgSO from "../../assets/image/job-site/png/hero-oval-shape.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="hero-area-03 bg-gradient-4 pt-20 pt-lg-32 pb-16 position-relative text-white">
        <div
          className="shape-1 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <img src={imgS1} alt="" />
        </div>
        <div
          className="shape-2 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="800"
          data-aos-delay="800"
        >
          <img src={imgS2} alt="" />
        </div>
        <div
          className="shape-3 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="1100"
          data-aos-delay="1100"
        >
          <img src={imgS3} alt="" />
        </div>
        <div className="wave-shape">
          <img src={imgHW} alt="" className="w-100 light-shape default-shape" />
          <img src={imgHWD} alt="" className="w-100 dark-shape" />
        </div>
      </div>
    </>
  );
};

export default Hero;
